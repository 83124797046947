import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

export const IndexPageTemplate = () => <div></div>;

IndexPageTemplate.propTypes = {};

const IndexPage = ({ data }) => {
  // on mount, redirect to the Everdays homepage
  useEffect(() => {
    // redirect to Everdays home if journeys.everdays.com/ is accessed,
    // unless it is for admin password reset which uses the journeys index page
    // the password reset url looks like: 'https://journeys.everdays.com/#recovery_token=sometoken
    // and also removes the token from the url after a split second
    if (!window?.location?.href?.includes("/#"))
      window.location.replace("https://everdays.com/");
  }, []);

  return <IndexPageTemplate />;
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
      }
    }
  }
`;
